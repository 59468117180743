// layouts
import { CONFIG } from '../../config-global';
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
// ----------------------------------------------------------------------

export default function Login(){
return (
  <LoginLayout>
    <AuthLoginForm />
  </LoginLayout>
);
}
