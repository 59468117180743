import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import { CONFIG } from '../../../config-global';

// ----------------------------------------------------------------------
const regEx = /^[^2]*/
const initialState = {
  intial: false,
  responseMessage: null,
  success: false,
  isLoading: false,
  error: null,
  contact: {},
  contacts: [],
  activeContacts: [],
  filterBy: '',
  page: 0,
  rowsPerPage: 100,
};

const slice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.initial = true;
    },

    // GET 
    getContactsSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.contacts = action.payload;
      state.initial = true;
    },

    // GET 
    getActiveContactsSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeContacts = action.payload;
      state.initial = true;
    },

    // GET 
    getContactSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.contact = action.payload;
      state.initial = true;
    },

    // SET RES MESSAGE
    setResponseMessage(state, action) {
      state.responseMessage = action.payload;
      state.isLoading = false;
      state.success = true;
      state.initial = true;
    },

    // RESET 
    resetContact(state){
      state.contact = {};
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // reset  
    resetActiveContacts(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeContacts = [];
      state.initial = true;
    },

    // RESET 
    resetContacts(state){
      state.contacts = [];
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },
        // Set FilterBy
    setFilterBy(state, action) {
      state.filterBy = action.payload;
    },
    // Set PageRowCount
    ChangeRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    // Set PageNo
    ChangePage(state, action) {
      state.page = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetContacts,
  resetActiveContacts,
  resetContact,
  setFilterBy,
  ChangeRowsPerPage,
  ChangePage,
} = slice.actions;
// ----------------------------------------------------------------------

export function addContact(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        title : params.title,
        firstName : params.firstName,
        lastName : params.lastName,
        designation : params.designation?._id,
        organization : params.organization?._id,
        category : params.category?._id,
        relationWithBargad : params.relationWithBargad,
        streetAddress : params.streetAddress,
        unionCouncil : params.unionCouncil,
        city : params.city,
        province : params.province?._id,
        district : params.district?._id,
        contact : params.contact?._id,
        fax : params.fax,
        email : params.email,
        phone :  params.phone,
        isActive :  params.isActive,
        status :  params.status,
      }

      const response = await axios.post(`${CONFIG.SERVER_URL}contacts/`, data);
      dispatch(slice.actions.setResponseMessage('Contact Saved successfully'));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
      // dispatch(slice.actions.hasError(error?.message));
    }
  };
}
// ----------------------------------------------------------------------

export function updateContact(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const data = {
        title : params.title,
        firstName : params.firstName,
        lastName : params.lastName,
        designation : params.designation?._id,
        organization : params.organization?._id,
        category : params.category?._id,
        relationWithBargad : params.relationWithBargad,
        streetAddress : params.streetAddress,
        unionCouncil : params.unionCouncil,
        city : params.city,
        province : params.province?._id,
        district : params.district?._id,
        contact : params.contact?._id,
        fax : params.fax,
        email : params.email,
        phone :  params.phone,
        isActive :  params.isActive,
        status :  params.status,
      }
      await axios.patch(`${CONFIG.SERVER_URL}contacts/update/${id}`, data);
      dispatch(slice.actions.setResponseMessage('Contact updated successfully'));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function getContacts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}contacts`);
      dispatch(slice.actions.getContactsSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Contacts loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

export function getActiveContact() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}contacts`,
      {
        params: {
          isActive: true,
        }
      }
      );
      dispatch(slice.actions.getActiveContactsSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Active Contacts loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getContact(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}contacts/${id}`);
      dispatch(slice.actions.getContactSuccess(response.data));
    } catch (error) {
      console.error(error?.message);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteContact(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try{
      const response = await axios.get(`${CONFIG.SERVER_URL}contacts/delete/${id}`);
      if(regEx.test(response.status)){
        dispatch(slice.actions.setResponseMessage(response.data));
        dispatch(resetContact());
      }
      return response;
    } catch (error) {
      console.error(error?.message);
      throw error;
    }
  };
}
