import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import { CONFIG } from '../../../config-global';

// ----------------------------------------------------------------------
const regEx = /^[^2]*/
const initialState = {
  intial: false,
  responseMessage: null,
  success: false,
  isLoading: false,
  error: null,
  university: {},
  universities: [],
  activeUniversities: [],
  filterBy: '',
  page: 0,
  rowsPerPage: 100,
};

const slice = createSlice({
  name: 'university',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.initial = true;
    },

    // GET 
    getUniversitiesSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.universities = action.payload;
      state.initial = true;
    },

    // GET 
    getActiveUniversitiesSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeUniversities = action.payload;
      state.initial = true;
    },

    // GET 
    getUniversitySuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.university = action.payload;
      state.initial = true;
    },

    // SET RES MESSAGE
    setResponseMessage(state, action) {
      state.responseMessage = action.payload;
      state.isLoading = false;
      state.success = true;
      state.initial = true;
    },

    // RESET 
    resetUniversity(state){
      state.university = {};
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // reset  
    resetActiveUniversities(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeUniversities = [];
      state.initial = true;
    },

    // RESET 
    resetUniversities(state){
      state.universities = [];
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },
        // Set FilterBy
    setFilterBy(state, action) {
      state.filterBy = action.payload;
    },
    // Set PageRowCount
    ChangeRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    // Set PageNo
    ChangePage(state, action) {
      state.page = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetUniversities,
  resetActiveUniversities,
  resetUniversity,
  setFilterBy,
  ChangeRowsPerPage,
  ChangePage,
} = slice.actions;
// ----------------------------------------------------------------------

export function addUniversity(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        name: params.name,
        desc: params.desc,
        region: params.region?._id,
        district: params.district?._id,
        isActive: params.isActive,
        status: params.status,
      }

      const response = await axios.post(`${CONFIG.SERVER_URL}universities/`, data);
      dispatch(slice.actions.setResponseMessage('University Saved successfully'));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
      // dispatch(slice.actions.hasError(error?.message));
    }
  };
}
// ----------------------------------------------------------------------

export function updateUniversity(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const data = {
        name: params.name,
        desc: params.desc,
        region: params.region?._id,
        district: params.district?._id,
        isActive: params.isActive,
        status: params.status,
      }
      await axios.patch(`${CONFIG.SERVER_URL}universities/update/${id}`, data);
      dispatch(slice.actions.setResponseMessage('University updated successfully'));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function getUniversities() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}universities`);
      dispatch(slice.actions.getUniversitiesSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Universities loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

export function getActiveUniversity() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}universities`,
      {
        params: {
          isActive: true,
        }
      }
      );
      dispatch(slice.actions.getActiveUniversitiesSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Active Universities loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getUniversity(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}universities/${id}`);
      dispatch(slice.actions.getUniversitySuccess(response.data));
    } catch (error) {
      console.error(error?.message);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteUniversity(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try{
      const response = await axios.get(`${CONFIG.SERVER_URL}universities/delete/${id}`);
      if(regEx.test(response.status)){
        dispatch(slice.actions.setResponseMessage(response.data));
        dispatch(resetUniversity());
      }
      return response;
    } catch (error) {
      console.error(error?.message);
      throw error;
    }
  };
}
