import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import { CONFIG } from '../../../config-global';

// ----------------------------------------------------------------------
const regEx = /^[^2]*/
const initialState = {
  intial: false,
  responseMessage: null,
  success: false,
  isLoading: false,
  error: null,
  activityType: {},
  activityTypes: [],
  activeActivityType: [],
  filterBy: '',
  page: 0,
  rowsPerPage: 100,
};

const slice = createSlice({
  name: 'activityType',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.initial = true;
    },

    // GET 
    getActivityTypesSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activityTypes = action.payload;
      state.initial = true;
    },

    // GET 
    getActiveActivityTypesSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeActivityTypes = action.payload;
      state.initial = true;
    },

    // GET 
    getActivityTypeSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activityType = action.payload;
      state.initial = true;
    },

    // SET RES MESSAGE
    setResponseMessage(state, action) {
      state.responseMessage = action.payload;
      state.isLoading = false;
      state.success = true;
      state.initial = true;
    },

    // RESET 
    resetActivityType(state){
      state.activityType = {};
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // reset  
    resetActiveActivityTypes(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeActivityTypes = [];
      state.initial = true;
    },

    // RESET 
    resetActivityTypes(state){
      state.activityTypes = [];
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },
        // Set FilterBy
    setFilterBy(state, action) {
      state.filterBy = action.payload;
    },
    // Set PageRowCount
    ChangeRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    // Set PageNo
    ChangePage(state, action) {
      state.page = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetActivityTypes,
  resetActiveActivityTypes,
  resetActivityType,
  setFilterBy,
  ChangeRowsPerPage,
  ChangePage,
} = slice.actions;
// ----------------------------------------------------------------------

export function addActivityType(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        name: params.name,
        desc: params.desc,
        type: params.type,
        status: 'active',
        isActive: params.isActive,
      }

      const response = await axios.post(`${CONFIG.SERVER_URL}activityTypes/`, data);
      dispatch(slice.actions.setResponseMessage('ActivityType Saved successfully'));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
      // dispatch(slice.actions.hasError(error?.message));
    }
  };
}
// ----------------------------------------------------------------------

export function updateActivityType(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const data = {
        name: params.name,
        desc: params.desc,
        type: params.type,
        status: 'active',
        isActive: params.isActive,
      }
      await axios.patch(`${CONFIG.SERVER_URL}activityTypes/update/${id}`, data);
      dispatch(slice.actions.setResponseMessage('ActivityType updated successfully'));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function getActivityTypes() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}activityTypes`);
      dispatch(slice.actions.getActivityTypesSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('ActivityTypes loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

export function getActiveActivityType() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}activityTypes`,
      {
        params: {
          isActive: true,
        }
      }
      );
      dispatch(slice.actions.getActiveActivityTypesSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Active ActivityTypes loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getActivityType(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}activityTypes/${id}`);
      dispatch(slice.actions.getActivityTypeSuccess(response.data));
    } catch (error) {
      console.error(error?.message);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteActivityType(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try{
      const response = await axios.get(`${CONFIG.SERVER_URL}activityTypes/delete/${id}`);
      if(regEx.test(response.status)){
        dispatch(slice.actions.setResponseMessage(response.data));
        dispatch(resetActivityType());
      }
      return response;
    } catch (error) {
      console.error(error?.message);
      throw error;
    }
  };
}
