import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')));
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
// export const Authenticate = Loadable(lazy(() => import('../sections/auth/Authenticate')));

// ----------------------------------------------------------------

// MAIN
export const PermissionDeniedPage = Loadable( lazy(() => import('../pages/dashboard/PermissionDeniedPage')));
export const BlankPage = Loadable(lazy(() => import('../pages/dashboard/BlankPage')));
export const Page500 = Loadable(lazy(() => import('../pages/Page500')));
export const Page403 = Loadable(lazy(() => import('../pages/Page403')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));
export const ErrorPage = Loadable(lazy(() => import('../pages/ErrorPage')));

// ----------------------------------------------------------------

// DASHBOARD
export const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));

// ----------------------------------------------------------------

// CUSTOMER
export const CustomerList = Loadable(lazy(() => import('../pages/customers/CustomerList')));
export const CustomerAdd = Loadable(lazy(() => import('../pages/customers/CustomerAddForm')));
export const CustomerEdit = Loadable(lazy(() => import('../pages/customers/CustomerEditForm')));
export const CustomerView = Loadable(lazy(() => import('../pages/customers/CustomerViewForm')));

// CUSTOMERS SITE LIST
export const SiteList = Loadable(lazy(() => import('../pages/customers/sites/SiteList')));

// // CUSTOMERS ORDER LIST
// export const OrderList = Loadable(lazy(() => import('../pages/customers/orders/OrderList')));
// export const OrderAdd = Loadable(lazy(() => import('../pages/customers/orders/OrderAddForm')));
// export const OrderEdit = Loadable(lazy(() => import('../pages/customers/orders/OrderEdit')));
// export const OrderView = Loadable(lazy(() => import('../pages/customers/orders/OrderViewForm')));

// SETTINGS
export const Setting = Loadable(lazy(() => import('../pages/settings/Setting')));


// SECURITY USERS
export const UserList = Loadable(lazy(() => import('../pages/security/users/UserList')));
export const UserAdd = Loadable(lazy(() => import('../pages/security/users/UserAddForm')));
export const UserView = Loadable(lazy(() => import('../pages/security/users/UserViewForm')));
export const UserEdit = Loadable(lazy(() => import('../pages/security/users/UserEditForm')));

// SECURITY SETTIGS ROLES
export const RoleList = Loadable(lazy(() => import('../pages/settings/securitySettings/role/RoleList')));
export const RoleAdd = Loadable(lazy(() => import('../pages/settings/securitySettings/role/RoleAddForm')));
export const RoleEdit = Loadable(lazy(() => import('../pages/settings/securitySettings/role/RoleEditForm')));

// ITEM CATEGORIES
export const CategoryList = Loadable(lazy(() => import('../pages/settings/categories/CategoryList')));
export const CategoryAdd = Loadable(lazy(() => import('../pages/settings/categories/CategoryAddForm')));
export const CategoryEdit = Loadable(lazy(() => import('../pages/settings/categories/CategoryEditForm')));
export const CategoryView = Loadable(lazy(() => import('../pages/settings/categories/CategoryViewForm')));

// 
export const OrganizationList = Loadable(lazy(() => import('../pages/settings/organizations/OrganizationList')));
export const OrganizationAdd = Loadable(lazy(() => import('../pages/settings/organizations/OrganizationAddForm')));
export const OrganizationEdit = Loadable(lazy(() => import('../pages/settings/organizations/OrganizationEditForm')));
export const OrganizationView = Loadable(lazy(() => import('../pages/settings/organizations/OrganizationViewForm')));

// 
export const DistrictList = Loadable(lazy(() => import('../pages/settings/districts/DistrictList')));
export const DistrictAdd = Loadable(lazy(() => import('../pages/settings/districts/DistrictAddForm')));
export const DistrictEdit = Loadable(lazy(() => import('../pages/settings/districts/DistrictEditForm')));
export const DistrictView = Loadable(lazy(() => import('../pages/settings/districts/DistrictViewForm')));


export const RegionList = Loadable(lazy(() => import('../pages/settings/regions/RegionList')));
export const RegionAdd = Loadable(lazy(() => import('../pages/settings/regions/RegionAddForm')));
export const RegionEdit = Loadable(lazy(() => import('../pages/settings/regions/RegionEditForm')));
export const RegionView = Loadable(lazy(() => import('../pages/settings/regions/RegionViewForm')));

export const ThemeList = Loadable(lazy(() => import('../pages/settings/themes/ThemeList')));
export const ThemeAdd = Loadable(lazy(() => import('../pages/settings/themes/ThemeAddForm')));
export const ThemeEdit = Loadable(lazy(() => import('../pages/settings/themes/ThemeEditForm')));
export const ThemeView = Loadable(lazy(() => import('../pages/settings/themes/ThemeViewForm')));

export const DesignationList = Loadable(lazy(() => import('../pages/settings/designations/DesignationList')));
export const DesignationAdd = Loadable(lazy(() => import('../pages/settings/designations/DesignationAddForm')));
export const DesignationEdit = Loadable(lazy(() => import('../pages/settings/designations/DesignationEditForm')));
export const DesignationView = Loadable(lazy(() => import('../pages/settings/designations/DesignationViewForm')));

export const NewsList = Loadable(lazy(() => import('../pages/settings/news/NewsList')));
export const NewsAdd = Loadable(lazy(() => import('../pages/settings/news/NewsAddForm')));
export const NewsEdit = Loadable(lazy(() => import('../pages/settings/news/NewsEditForm')));
export const NewsView = Loadable(lazy(() => import('../pages/settings/news/NewsViewForm')));

export const ActivityTypeList = Loadable(lazy(() => import('../pages/settings/activityTypes/ActivityTypeList')));
export const ActivityTypeAdd = Loadable(lazy(() => import('../pages/settings/activityTypes/ActivityTypeAddForm')));
export const ActivityTypeEdit = Loadable(lazy(() => import('../pages/settings/activityTypes/ActivityTypeEditForm')));
export const ActivityTypeView = Loadable(lazy(() => import('../pages/settings/activityTypes/ActivityTypeViewForm')));

export const ObjectiveList = Loadable(lazy(() => import('../pages/settings/objectives/ObjectiveList')));
export const ObjectiveAdd = Loadable(lazy(() => import('../pages/settings/objectives/ObjectiveAddForm')));
export const ObjectiveEdit = Loadable(lazy(() => import('../pages/settings/objectives/ObjectiveEditForm')));
export const ObjectiveView = Loadable(lazy(() => import('../pages/settings/objectives/ObjectiveViewForm')));

export const AwardList = Loadable(lazy(() => import('../pages/awards/AwardList')));
export const AwardAdd = Loadable(lazy(() => import('../pages/awards/AwardAddForm')));
export const AwardEdit = Loadable(lazy(() => import('../pages/awards/AwardEditForm')));
export const AwardView = Loadable(lazy(() => import('../pages/awards/AwardViewForm')));

export const ContactList = Loadable(lazy(() => import('../pages/contacts/ContactList')));
export const ContactAdd = Loadable(lazy(() => import('../pages/contacts/ContactAddForm')));
export const ContactEdit = Loadable(lazy(() => import('../pages/contacts/ContactEditForm')));
export const ContactView = Loadable(lazy(() => import('../pages/contacts/ContactViewForm')));

export const BoardOfDirectorList = Loadable(lazy(() => import('../pages/boardOfDirectors/BoardOfDirectorList')));
export const BoardOfDirectorAdd = Loadable(lazy(() => import('../pages/boardOfDirectors/BoardOfDirectorAddForm')));
export const BoardOfDirectorEdit = Loadable(lazy(() => import('../pages/boardOfDirectors/BoardOfDirectorEditForm')));
export const BoardOfDirectorView = Loadable(lazy(() => import('../pages/boardOfDirectors/BoardOfDirectorViewForm')));

export const ActivityList = Loadable(lazy(() => import('../pages/activities/ActivityList')));
export const ActivityAdd = Loadable(lazy(() => import('../pages/activities/ActivityAddForm')));
export const ActivityEdit = Loadable(lazy(() => import('../pages/activities/ActivityEditForm')));
export const ActivityView = Loadable(lazy(() => import('../pages/activities/ActivityViewForm')));

export const ProjectList = Loadable(lazy(() => import('../pages/projects/ProjectList')));
export const ProjectAdd = Loadable(lazy(() => import('../pages/projects/ProjectAddForm')));
export const ProjectEdit = Loadable(lazy(() => import('../pages/projects/ProjectEditForm')));
export const ProjectView = Loadable(lazy(() => import('../pages/projects/ProjectViewForm')));

export const UniversityList = Loadable(lazy(() => import('../pages/universities/UniversityList')));
export const UniversityAdd = Loadable(lazy(() => import('../pages/universities/UniversityAddForm')));
export const UniversityEdit = Loadable(lazy(() => import('../pages/universities/UniversityEditForm')));
export const UniversityView = Loadable(lazy(() => import('../pages/universities/UniversityViewForm')));
// ----------------------------------------------------------------
