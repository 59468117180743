import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import { CONFIG } from '../../../config-global';

// ----------------------------------------------------------------------
const regEx = /^[^2]*/
const initialState = {
  intial: false,
  responseMessage: null,
  success: false,
  isLoading: false,
  error: null,
  designation: {},
  designations: [],
  activeDesignation: [],
  filterBy: '',
  page: 0,
  rowsPerPage: 100,
};

const slice = createSlice({
  name: 'designation',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.initial = true;
    },

    // GET 
    getDesignationsSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.designations = action.payload;
      state.initial = true;
    },

    // GET 
    getActiveDesignationsSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeDesignations = action.payload;
      state.initial = true;
    },

    // GET 
    getDesignationSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.designation = action.payload;
      state.initial = true;
    },

    // SET RES MESSAGE
    setResponseMessage(state, action) {
      state.responseMessage = action.payload;
      state.isLoading = false;
      state.success = true;
      state.initial = true;
    },

    // RESET 
    resetDesignation(state){
      state.designation = {};
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // reset  
    resetActiveDesignations(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeDesignations = [];
      state.initial = true;
    },

    // RESET 
    resetDesignations(state){
      state.designations = [];
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },
        // Set FilterBy
    setFilterBy(state, action) {
      state.filterBy = action.payload;
    },
    // Set PageRowCount
    ChangeRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    // Set PageNo
    ChangePage(state, action) {
      state.page = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetDesignations,
  resetActiveDesignations,
  resetDesignation,
  setFilterBy,
  ChangeRowsPerPage,
  ChangePage,
} = slice.actions;
// ----------------------------------------------------------------------

export function addDesignation(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        name: params.name,
        desc: params.desc,
        type: params.type,
        status: 'active',
        isActive: params.isActive,
      }

      const response = await axios.post(`${CONFIG.SERVER_URL}designations/`, data);
      dispatch(slice.actions.setResponseMessage('Designation Saved successfully'));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
      // dispatch(slice.actions.hasError(error?.message));
    }
  };
}
// ----------------------------------------------------------------------

export function updateDesignation(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const data = {
        name: params.name,
        desc: params.desc,
        type: params.type,
        status: 'active',
        isActive: params.isActive,
      }
      await axios.patch(`${CONFIG.SERVER_URL}designations/update/${id}`, data);
      dispatch(slice.actions.setResponseMessage('Designation updated successfully'));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function getDesignations() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}designations`);
      dispatch(slice.actions.getDesignationsSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Designations loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

export function getActiveDesignation() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}designations`,
      {
        params: {
          isActive: true,
        }
      }
      );
      dispatch(slice.actions.getActiveDesignationsSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Active Designations loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getDesignation(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}designations/${id}`);
      dispatch(slice.actions.getDesignationSuccess(response.data));
    } catch (error) {
      console.error(error?.message);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteDesignation(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try{
      const response = await axios.get(`${CONFIG.SERVER_URL}designations/delete/${id}`);
      if(regEx.test(response.status)){
        dispatch(slice.actions.setResponseMessage(response.data));
        dispatch(resetDesignation());
      }
      return response;
    } catch (error) {
      console.error(error?.message);
      throw error;
    }
  };
}
