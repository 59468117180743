import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import { CONFIG } from '../../../config-global';

// ----------------------------------------------------------------------
const regEx = /^[^2]*/
const initialState = {
  intial: false,
  responseMessage: null,
  success: false,
  isLoading: false,
  error: null,
  type: {},
  types: [],
  activeTypes: [],
  filterBy: '',
  page: 0,
  rowsPerPage: 100,
  addFileDialog:false,
};

const slice = createSlice({
  name: 'type',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.initial = true;
    },

    // GET  ITEM CATEGORIES
    getTypesSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.types = action.payload;
      state.initial = true;
    },

    // GET ACTIVE ITEMS
    getActiveTypesSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeTypes = action.payload;
      state.initial = true;
    },

    // GET ITEM
    getTypeSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.type = action.payload;
      state.initial = true;
    },

    // SET RES MESSAGE
    setResponseMessage(state, action) {
      state.responseMessage = action.payload;
      state.isLoading = false;
      state.success = true;
      state.initial = true;
    },

    // RESET ITEM
    resetType(state){
      state.type = {};
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // reset ACTIVE ITEMS
    resetActiveTypes(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeTypes = [];
      state.initial = true;
    },

    // RESET ITEM CATEGORIES
    resetTypes(state){
      state.types = [];
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },
        // Set FilterBy
    setFilterBy(state, action) {
      state.filterBy = action.payload;
    },
    // Set PageRowCount
    ChangeRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    // Set PageNo
    ChangePage(state, action) {
      state.page = action.payload;
    },

    // SET ADD FILE DIALOG
    setTypeAddFileDialog(state, action) {
      state.addFileDialog = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetTypes,
  resetActiveTypes,
  resetType,
  setFilterBy,
  ChangeRowsPerPage,
  ChangePage,
  setTypeAddFileDialog
} = slice.actions;
// ----------------------------------------------------------------------

export function addType(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        name: params.name,
        category: params.category._id,
        desc: params.desc,
        isActive: params.isActive
      }

      const response = await axios.post(`${CONFIG.SERVER_URL}types/`, data);
      dispatch(slice.actions.setResponseMessage('Type Saved successfully'));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
      // dispatch(slice.actions.hasError(error?.message));
    }
  };
}
// ----------------------------------------------------------------------

export function updateType(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        name: params.name,
        category: params.category._id,
        desc: params.desc,
        isActive: params.isActive
      }
      await axios.patch(`${CONFIG.SERVER_URL}types/update/${id}`, data);
      dispatch(slice.actions.setResponseMessage('Type updated successfully'));
    } catch (error) {
      console.log(error);
      throw error;
      // dispatch(slice.actions.hasError(error?.message));
    }
  };
}
// ----------------------------------------------------------------------

export function getTypes(categoryId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      
      const params = {};

      if(categoryId){
        params.category=categoryId;
      }

      const response = await axios.get(`${CONFIG.SERVER_URL}types`,{params});
      dispatch(slice.actions.getTypesSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Types loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

export function getActiveTypes(categoryId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const params = {
        isActive:true,
      }

      if(categoryId){
        params.category=categoryId;
      }

      const response = await axios.get(`${CONFIG.SERVER_URL}types`,{params});
      dispatch(slice.actions.getActiveTypesSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Active Types loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getType(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}types/${id}`);
      dispatch(slice.actions.getTypeSuccess(response.data));
    } catch (error) {
      console.error(error?.message);
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteType(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try{
      const response = await axios.get(`${CONFIG.SERVER_URL}types/delete/${id}`,
      {
        isArchived: true, 
      }
      );
      if(regEx.test(response.status)){
        dispatch(slice.actions.setResponseMessage(response.data));
        dispatch(resetType());
      }
      return response;
    } catch (error) {
      console.error(error?.message);
      throw error;
    }
  };
}
