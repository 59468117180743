// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOT_AUTH = '/auth';
const ROOT_DASHBOARD = '/dashboard';
const ROOT_CUSTOMERS = '/customers';
const ROOT_SECURITY = '/security';
const ROOT_SETTING = '/settings';
const ROOT_ORDERS = '/orders';
const ROOT_PROFILE = '/profile';
const ROOT_AWARDS = '/awards';
const ROOT_UNIVERSITIES = '/universities';
const ROOT_CONTACT = '/contacts';
const ROOT_BOARD = '/boardOfDirectors';
const ROOT_ACTIVITY = '/activities';
const ROOT_PROJECT = '/projects';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOT_AUTH,
  login: path(ROOT_AUTH, '/login'),
  register: path(ROOT_AUTH, '/register'),
  loginUnprotected: path(ROOT_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOT_AUTH, '/register-unprotected'),
  verify: path(ROOT_AUTH, '/verify'),
  resetPassword: path(ROOT_AUTH, '/reset-password'),
  newpassword: (token, userId) => path(ROOT_AUTH, `/new-password/${token}/${userId}`),
  authenticate: path(ROOT_AUTH, '/authenticate'),
  // newPassword: path(ROOT_AUTH, '/new-password/${id}/asset/${userId}/edit'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  invalidErrorPage:'/InvalidErrorPage',
  expiredErrorPage:'/ExpiredErrorPage',
};

// --------------------- Dashboard ----------------------
export const PATH_DASHBOARD = {
  root: ROOT_DASHBOARD,
  permissionDenied: path(ROOT_DASHBOARD, '/permission-denied'),
  blank: path(ROOT_AUTH, '/login')
};

export const PATH_PROFILE = {
  view: (id) => path(ROOT_PROFILE, `/${id}/`),
  edit: (id) => path(ROOT_PROFILE, `/${id}/edit`),
  sites: (id) => path(ROOT_PROFILE, `/${id}/sites`),
};


// --------------------- Customer -----------------------
export const PATH_CUSTOMERS = {
  root:ROOT_CUSTOMERS,
  new: path(ROOT_CUSTOMERS, '/new'),
  view: (id) => path(ROOT_CUSTOMERS, `/${id}/view`),
  edit: (id) => path(ROOT_CUSTOMERS, `/${id}/edit`),
  sites: (id) => path(ROOT_CUSTOMERS, `/${id}/sites`), 
};


export const PATH_AWARDS = {
  root:ROOT_AWARDS,
  new: path(ROOT_AWARDS, '/new'),
  view: (id) => path(ROOT_AWARDS, `/${id}/view`),
  edit: (id) => path(ROOT_AWARDS, `/${id}/edit`),
  sites: (id) => path(ROOT_AWARDS, `/${id}/sites`), 
};

export const PATH_UNIVERSITIES = {
  root:ROOT_UNIVERSITIES,
  new: path(ROOT_UNIVERSITIES, '/new'),
  view: (id) => path(ROOT_UNIVERSITIES, `/${id}/view`),
  edit: (id) => path(ROOT_UNIVERSITIES, `/${id}/edit`),
};

export const PATH_CONTACT = {
  root:ROOT_CONTACT,
  new: path(ROOT_CONTACT, '/new'),
  view: (id) => path(ROOT_CONTACT, `/${id}/view`),
  edit: (id) => path(ROOT_CONTACT, `/${id}/edit`),
};

export const PATH_BOARD = {
  root:ROOT_BOARD,
  new: path(ROOT_BOARD, '/new'),
  view: (id) => path(ROOT_BOARD, `/${id}/view`),
  edit: (id) => path(ROOT_BOARD, `/${id}/edit`),
};

export const PATH_ACTIVITY = {
  root:ROOT_ACTIVITY,
  new: path(ROOT_ACTIVITY, '/new'),
  view: (id) => path(ROOT_ACTIVITY, `/${id}/view`),
  edit: (id) => path(ROOT_ACTIVITY, `/${id}/edit`),
};

export const PATH_PROJECT = {
  root:ROOT_PROJECT,
  new: path(ROOT_PROJECT, '/new'),
  view: (id) => path(ROOT_PROJECT, `/${id}/view`),
  edit: (id) => path(ROOT_PROJECT, `/${id}/edit`),
};

export const PATH_ORDERS = {
  root:ROOT_ORDERS,
  new:path(ROOT_ORDERS,`/new`),
  view: (id) => path(ROOT_ORDERS,`/${id}/view`),
  edit: (id) => path(ROOT_ORDERS,`/${id}/edit`)
};

export const PATH_SETTING = {
  permissionDenied: path(ROOT_SETTING, '/permission-denied'),
  root: ROOT_SETTING,
  role: {
    new: path(ROOT_SETTING, '/role/new'),
    list: path(ROOT_SETTING, '/role/list'),
    edit: (id) => path(ROOT_SETTING, `/role/${id}/edit`),
  },
  category: {
    new: path(ROOT_SETTING, '/category/new'),
    list: path(ROOT_SETTING, '/category/list'),
    view: (id) => path(ROOT_SETTING, `/category/${id}/view`),
    edit: (id) => path(ROOT_SETTING, `/category/${id}/edit`),
  },
  organization: {
    new: path(ROOT_SETTING, '/organization/new'),
    list: path(ROOT_SETTING, '/organization/list'),
    view: (id) => path(ROOT_SETTING, `/organization/${id}/view`),
    edit: (id) => path(ROOT_SETTING, `/organization/${id}/edit`),
  },
  region: {
    new: path(ROOT_SETTING, '/region/new'),
    list: path(ROOT_SETTING, '/region/list'),
    view: (id) => path(ROOT_SETTING, `/region/${id}/view`),
    edit: (id) => path(ROOT_SETTING, `/region/${id}/edit`),
  },
  district: {
    new: path(ROOT_SETTING, '/district/new'),
    list: path(ROOT_SETTING, '/district/list'),
    view: (id) => path(ROOT_SETTING, `/district/${id}/view`),
    edit: (id) => path(ROOT_SETTING, `/district/${id}/edit`),
  },
  theme: {
    new: path(ROOT_SETTING, '/theme/new'),
    list: path(ROOT_SETTING, '/theme/list'),
    view: (id) => path(ROOT_SETTING, `/theme/${id}/view`),
    edit: (id) => path(ROOT_SETTING, `/theme/${id}/edit`),
  },
  news: {
    new: path(ROOT_SETTING, '/news/new'),
    list: path(ROOT_SETTING, '/news/list'),
    view: (id) => path(ROOT_SETTING, `/news/${id}/view`),
    edit: (id) => path(ROOT_SETTING, `/news/${id}/edit`),
  },
  objective: {
    new: path(ROOT_SETTING, '/objective/new'),
    list: path(ROOT_SETTING, '/objective/list'),
    view: (id) => path(ROOT_SETTING, `/objective/${id}/view`),
    edit: (id) => path(ROOT_SETTING, `/objective/${id}/edit`),
  },
  designation: {
    new: path(ROOT_SETTING, '/designation/new'),
    list: path(ROOT_SETTING, '/designation/list'),
    view: (id) => path(ROOT_SETTING, `/designation/${id}/view`),
    edit: (id) => path(ROOT_SETTING, `/designation/${id}/edit`),
  },
  activityType: {
    new: path(ROOT_SETTING, '/activityType/new'),
    list: path(ROOT_SETTING, '/activityType/list'),
    view: (id) => path(ROOT_SETTING, `/activityType/${id}/view`),
    edit: (id) => path(ROOT_SETTING, `/activityType/${id}/edit`),
  },
};

export const PATH_SECURITY = {
  root: ROOT_SECURITY,
  permissionDenied: path(ROOT_SECURITY, '/permission-denied'),
  // ------------------------ SECURITY USERS ----------------------------------------
  user: {
    new: path(ROOT_SECURITY, `/user/new/`),
    view: (id) => path(ROOT_SECURITY, `/user/${id}/view`),
    edit: (id) => path(ROOT_SECURITY, `/user/${id}/edit`),
  },
};
