import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import { CONFIG } from '../../../config-global';

// ----------------------------------------------------------------------
const regEx = /^[^2]*/
const initialState = {
  intial: false,
  responseMessage: null,
  success: false,
  isLoading: false,
  error: null,
  award: {},
  awards: [],
  activeAward: [],
  filterBy: '',
  page: 0,
  rowsPerPage: 100,
};

const slice = createSlice({
  name: 'award',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.initial = true;
    },

    // GET 
    getAwardsSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.awards = action.payload;
      state.initial = true;
    },

    // GET 
    getActiveAwardsSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeAwards = action.payload;
      state.initial = true;
    },

    // GET 
    getAwardSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.award = action.payload;
      state.initial = true;
    },

    // SET RES MESSAGE
    setResponseMessage(state, action) {
      state.responseMessage = action.payload;
      state.isLoading = false;
      state.success = true;
      state.initial = true;
    },

    // RESET 
    resetAward(state){
      state.award = {};
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // reset  
    resetActiveAwards(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeAwards = [];
      state.initial = true;
    },

    // RESET 
    resetAwards(state){
      state.awards = [];
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },
        // Set FilterBy
    setFilterBy(state, action) {
      state.filterBy = action.payload;
    },
    // Set PageRowCount
    ChangeRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    // Set PageNo
    ChangePage(state, action) {
      state.page = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetAwards,
  resetActiveAwards,
  resetAward,
  setFilterBy,
  ChangeRowsPerPage,
  ChangePage,
} = slice.actions;
// ----------------------------------------------------------------------

export function addAward(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        name: params.name,
        awardToBargad: params.awardToBargad,
        awardBy: params.awardBy,
        isActive: params.isActive,
        status: params.status,
      }

      const response = await axios.post(`${CONFIG.SERVER_URL}awards/`, data);
      dispatch(slice.actions.setResponseMessage('Award Saved successfully'));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
      // dispatch(slice.actions.hasError(error?.message));
    }
  };
}
// ----------------------------------------------------------------------

export function updateAward(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const data = {
        name: params.name,
        awardToBargad: params.awardToBargad,
        awardBy: params.awardBy,
        isActive: params.isActive,
        status: params.status,
      }
      await axios.patch(`${CONFIG.SERVER_URL}awards/update/${id}`, data);
      dispatch(slice.actions.setResponseMessage('Award updated successfully'));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function getAwards() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}awards`);
      dispatch(slice.actions.getAwardsSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Awards loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

export function getActiveAward() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}awards`,
      {
        params: {
          isActive: true,
        }
      }
      );
      dispatch(slice.actions.getActiveAwardsSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Active Awards loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getAward(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}awards/${id}`);
      dispatch(slice.actions.getAwardSuccess(response.data));
    } catch (error) {
      console.error(error?.message);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteAward(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try{
      const response = await axios.get(`${CONFIG.SERVER_URL}awards/delete/${id}`);
      if(regEx.test(response.status)){
        dispatch(slice.actions.setResponseMessage(response.data));
        dispatch(resetAward());
      }
      return response;
    } catch (error) {
      console.error(error?.message);
      throw error;
    }
  };
}
