import PropTypes from 'prop-types';
import { createContext, useEffect, useContext, useMemo, useCallback } from 'react';
// hooks
import useLocalStorage from '../../hooks/useLocalStorage';
// utils
import localStorageAvailable from '../../utils/localStorageAvailable';
//
import { defaultSettings } from './config-setting';
import { defaultPreset, getPresets, presetsOption } from './presets';
import { useAuthContext } from '../../auth/useAuthContext';

// ----------------------------------------------------------------------

const initialState = {
  ...defaultSettings,
  onChangeDrawer: () => {},
  // Mode
  onChangeMode: () => {},
  // Direction
  onChangeDirection: () => {},
  // Layout
  onChangeLayout: () => {},
  onToggleLayout: () => {},
  // Contrast
  onChangeContrast: () => {},
  // Color
  onChangeColorPresets: () => {},
  presetsColor: defaultPreset,
  presetsOption: [],
  // Stretch
  // Reset
  onResetSetting: () => {},
};

// ----------------------------------------------------------------------

export const SettingsContext = createContext(initialState);

export const useSettingsContext = () => {
  const context = useContext(SettingsContext);

  if (!context) throw new Error('useSettingsContext must be use inside SettingsProvider');

  return context;
};

// ----------------------------------------------------------------------

SettingsProvider.propTypes = {
  children: PropTypes.node,
};

export function SettingsProvider({ children }) {

  const [settings, setSettings] = useLocalStorage('settings', defaultSettings);
  
   // Open Setting Drawer
   const onChangeDrawer = useCallback(
    (event) => {
      const toggleDrawer = !settings.toggleDrawer;
      setSettings({ ...settings, toggleDrawer });
    },
    [setSettings, settings]
  );
  
  // Mode
  
  const onChangeMode = useCallback(
    (event) => {
      const themeMode = event.target.value;
      setSettings({ ...settings, themeMode });
    },
    [setSettings, settings]
  );

  // Direction
  
  const onChangeDirection = useCallback(
    (event) => {
      const themeDirection = event.target.value;
      setSettings({ ...settings, themeDirection });
    },
    [setSettings, settings]
  );

  // Layout

  const onToggleLayout = useCallback(() => { 
    const themeLayout = settings.themeLayout === 'vertical' ? 'mini' : 'vertical'; 
    setSettings({ ...settings, themeLayout }); 
  }, [setSettings, settings]);
  
  const onChangeLayout = useCallback(
    (event) => {
      const themeLayout = event.target.value;
      setSettings({ ...settings, themeLayout });
    },
    [setSettings, settings]
  );

  // Contrast
  
  const onChangeContrast = useCallback(
    (event) => {
      const themeContrast = event.target.value;
      setSettings({ ...settings, themeContrast });
    },
    [setSettings, settings]
  );

  // Color
  const onChangeColorPresets = useCallback(
    (event) => {
      const themeColorPresets = event.target.value;
      setSettings({ ...settings, themeColorPresets });
    },
    [setSettings, settings]
  );

  // Stretch
  
  // Reset
  const onResetSetting = useCallback(() => {
    setSettings(defaultSettings);
  }, [setSettings]);

  const memoizedValue = useMemo(
    () => ({
      ...settings,
      onChangeDrawer,
      // Mode
      onChangeMode,
      // Direction
      onChangeDirection,
      // Layout
      onChangeLayout,
      onToggleLayout,
      // Contrast
      onChangeContrast,
      // Stretch
      // Color
      onChangeColorPresets,
      presetsOption,
      presetsColor: getPresets(settings.themeColorPresets),
      // Reset
      onResetSetting,
    }),
    [
      settings,
      onChangeDrawer,
      // Mode
      onChangeMode,
      // Direction
      onChangeDirection,
      // Layout
      onChangeLayout,
      onToggleLayout,
      onChangeContrast,
      // Contrast
      // Stretch
      // Color
      onChangeColorPresets,
      // Reset
      onResetSetting,
    ]
  );

  return <SettingsContext.Provider value={memoizedValue}>{children}</SettingsContext.Provider>;
}
