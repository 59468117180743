import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { CONFIG } from '../../config-global';

// ----------------------------------------------------------------------
const regEx = /^[^2]*/
const initialState = {
  intial: false,
  responseMessage: null,
  success: false,
  isLoading: false,
  error: null,
  activity: {},
  activities: [],
  activeActivities: [],
  filterBy: '',
  page: 0,
  rowsPerPage: 100,
};

const slice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.initial = true;
    },

    // GET 
    getActivitiesSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activities = action.payload;
      state.initial = true;
    },

    // GET 
    getActiveActivitiesSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeActivities = action.payload;
      state.initial = true;
    },

    // GET 
    getActivitySuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activity = action.payload;
      state.initial = true;
    },

    // SET RES MESSAGE
    setResponseMessage(state, action) {
      state.responseMessage = action.payload;
      state.isLoading = false;
      state.success = true;
      state.initial = true;
    },

    // RESET 
    resetActivity(state){
      state.activity = {};
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // reset  
    resetActiveActivities(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeActivities = [];
      state.initial = true;
    },

    // RESET 
    resetActivities(state){
      state.activities = [];
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },
        // Set FilterBy
    setFilterBy(state, action) {
      state.filterBy = action.payload;
    },
    // Set PageRowCount
    ChangeRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    // Set PageNo
    ChangePage(state, action) {
      state.page = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetActivities,
  resetActiveActivities,
  resetActivity,
  setFilterBy,
  ChangeRowsPerPage,
  ChangePage,
} = slice.actions;
// ----------------------------------------------------------------------

export function addActivity(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const formData = new FormData();
      formData.append("name", params.name);
      formData.append("desc", params.desc);
      formData.append("type", params.type?._id);
      formData.append("project", params.project?._id);
      formData.append("startDate",  params.startDate);
      formData.append("MOU", params.MOU);
      formData.append("MOUSector", params.MOUSector);
      formData.append("region", params.region?._id);
      formData.append("district", params.district?._id);
      formData.append("duration", params.duration);
      formData.append("theme", params.theme?._id);
      formData.append("skillImparted", params.skillImparted);
      formData.append("endDate", params.endDate);
      formData.append("mediaCoverage", params.mediaCoverage);
      formData.append("mediaLinks", params.mediaLinks);
      formData.append("isActive", params.isActive);
      formData.append("status", params.status);

      if(Array.isArray(params.participants) && params.participants.length>0) {
        params.participants.map((p)=>{
          return formData.append("participants[]", p._id);
        })
      }

      if(Array.isArray(params.trainers) && params.trainers.length>0) {
        params.trainers.map((p)=>{
          return formData.append("trainers[]", p._id);
        })
      }

      if(Array.isArray(params.reports) && params.reports.length>0) {
        params.reports.map((p)=>{
          return formData.append("reports", p);
        })
      }

      if(Array.isArray(params.images) && params.images.length>0) {
        params.images.map((p)=>{
          return formData.append("images", p);
        })
      }

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      const response = await axios.post(`${CONFIG.SERVER_URL}activities/`, formData, config);
      dispatch(slice.actions.setResponseMessage('Activity Saved successfully'));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
      // dispatch(slice.actions.hasError(error?.message));
    }
  };
}
// ----------------------------------------------------------------------

export function updateActivity(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const formData = new FormData();
      formData.append("name", params.name);
      formData.append("desc", params.desc);
      formData.append("type", params.type?._id);
      formData.append("project", params.project?._id);
      formData.append("startDate",  params.startDate);
      formData.append("MOU", params.MOU);
      formData.append("MOUSector", params.MOUSector);
      formData.append("region", params.region?._id);
      formData.append("district", params.district?._id);
      formData.append("duration", params.duration);
      formData.append("theme", params.theme?._id);
      formData.append("skillImparted", params.skillImparted);
      formData.append("endDate", params.endDate);
      formData.append("mediaCoverage", params.mediaCoverage);
      formData.append("mediaLinks", params.mediaLinks);
      formData.append("isActive", params.isActive);
      formData.append("status", params.status);

      if(Array.isArray(params.participants) && params.participants.length>0) {
        params.participants.map((p)=>{
          return formData.append("participants[]", p._id);
        })
      }

      if(Array.isArray(params.trainers) && params.trainers.length>0) {
        params.trainers.map((p)=>{
          return formData.append("trainers[]", p._id);
        })
      }

      if(Array.isArray(params.reports) && params.reports.length>0) {
        params.reports.map((p)=>{
          return formData.append("reports", p);
        })
      }

      if(Array.isArray(params.images) && params.images.length>0) {
        params.images.map((p)=>{
          return formData.append("images", p);
        })
      }

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      await axios.patch(`${CONFIG.SERVER_URL}activities/update/${id}`, formData, config);
      dispatch(slice.actions.setResponseMessage('Activity updated successfully'));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function getActivities() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}activities`);
      dispatch(slice.actions.getActivitiesSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Activities loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

export function getActiveActivity() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}activities`,
      {
        params: {
          isActive: true,
        }
      }
      );
      dispatch(slice.actions.getActiveActivitiesSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Active Activities loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getActivity(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}activities/${id}`);
      dispatch(slice.actions.getActivitySuccess(response.data));
    } catch (error) {
      console.error(error?.message);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteActivity(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try{
      const response = await axios.get(`${CONFIG.SERVER_URL}activities/delete/${id}`);
      if(regEx.test(response.status)){
        dispatch(slice.actions.setResponseMessage(response.data));
        dispatch(resetActivity());
      }
      return response;
    } catch (error) {
      console.error(error?.message);
      throw error;
    }
  };
}
