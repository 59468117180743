import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
} from '@mui/material';
// routes
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { useSnackbar } from '../../../components/snackbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
// import Drawer
import { useSettingsContext } from '../../../components/settings';
import { setChangePasswordDialog } from '../../../redux/slices/user/user';
import ChangePasswordDialog from '../../../components/Dialog/ChangePasswordDialog';
import { PATH_CUSTOMERS, PATH_PROFILE } from '../../../routes/paths';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, logout } = useAuthContext();
  const email = localStorage.getItem('email')
  const { enqueueSnackbar } = useSnackbar();
  const [openPopover, setOpenPopover] = useState(null);
  const { onChangeDrawer } = useSettingsContext();
  
  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleChangePassword = () => {
    dispatch(setChangePasswordDialog(true));
  };

  const handleLogout = async () => {
    try {
      logout();
      handleClosePopover();
    } catch (error) {
      console.error(error?.message);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  const handleToggleDrawer = () => {
    handleClosePopover();
    onChangeDrawer();
  };

  const handleProfileView = (path) => {
    handleClosePopover();
    navigate(PATH_PROFILE.view(user?.customer))
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
            },
          }),
        }}
      >
        <CustomAvatar src={user?.photoURL} alt={user?.firstName} name={user?.firstName} />
      </IconButtonAnimate>

      <MenuPopover arrow='top-right' open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.firstName} {user?.lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.login || email }
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: 'solid' }} />
        <Stack sx={{ p: 1 }}>
          <MenuItem onClick={handleProfileView}><Typography variant="body2" noWrap>Profile</Typography></MenuItem>
          <MenuItem onClick={handleToggleDrawer}><Typography variant="body2" noWrap>Theme Setting</Typography></MenuItem>
        </Stack>
        <Divider sx={{ borderStyle: 'solid' }} />
        <Stack sx={{ p: 1 }}>
          <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Stack>
      </MenuPopover>
      <ChangePasswordDialog />
    </>
  );
}
