import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import { CONFIG } from '../../../config-global';

// ----------------------------------------------------------------------
const regEx = /^[^2]*/
const initialState = {
  intial: false,
  responseMessage: null,
  success: false,
  isLoading: false,
  error: null,
  district: {},
  districts: [],
  activeDistrict: [],
  filterBy: '',
  page: 0,
  rowsPerPage: 100,
};

const slice = createSlice({
  name: 'district',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.initial = true;
    },

    // GET 
    getDistrictsSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.districts = action.payload;
      state.initial = true;
    },

    // GET 
    getActiveDistrictsSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeDistricts = action.payload;
      state.initial = true;
    },

    // GET 
    getDistrictSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.district = action.payload;
      state.initial = true;
    },

    // SET RES MESSAGE
    setResponseMessage(state, action) {
      state.responseMessage = action.payload;
      state.isLoading = false;
      state.success = true;
      state.initial = true;
    },

    // RESET 
    resetDistrict(state){
      state.district = {};
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // reset  
    resetActiveDistricts(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeDistricts = [];
      state.initial = true;
    },

    // RESET 
    resetDistricts(state){
      state.districts = [];
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },
        // Set FilterBy
    setFilterBy(state, action) {
      state.filterBy = action.payload;
    },
    // Set PageRowCount
    ChangeRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    // Set PageNo
    ChangePage(state, action) {
      state.page = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetDistricts,
  resetActiveDistricts,
  resetDistrict,
  setFilterBy,
  ChangeRowsPerPage,
  ChangePage,
} = slice.actions;
// ----------------------------------------------------------------------

export function addDistrict(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        name: params.name,
        code: params.code,
        region: params.region,
        status: params.status,
        isActive: params.isActive,
      }

      const response = await axios.post(`${CONFIG.SERVER_URL}districts/`, data);
      dispatch(slice.actions.setResponseMessage('District Saved successfully'));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
      // dispatch(slice.actions.hasError(error?.message));
    }
  };
}
// ----------------------------------------------------------------------

export function updateDistrict(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const data = {
        name: params.name,
        code: params.code,
        region: params.region?._id,
        status: params.status,
        isActive: params.isActive,
      }
      await axios.patch(`${CONFIG.SERVER_URL}districts/update/${id}`, data);
      dispatch(slice.actions.setResponseMessage('District updated successfully'));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function getDistricts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}districts`);
      dispatch(slice.actions.getDistrictsSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Districts loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

export function getActiveDistrict() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}districts`,
      {
        params: {
          isActive: true,
        }
      }
      );
      dispatch(slice.actions.getActiveDistrictsSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Active Districts loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getDistrict(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}districts/${id}`);
      dispatch(slice.actions.getDistrictSuccess(response.data));
    } catch (error) {
      console.error(error?.message);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteDistrict(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try{
      const response = await axios.get(`${CONFIG.SERVER_URL}districts/delete/${id}`);
      if(regEx.test(response.status)){
        dispatch(slice.actions.setResponseMessage(response.data));
        dispatch(resetDistrict());
      }
      return response;
    } catch (error) {
      console.error(error?.message);
      throw error;
    }
  };
}
