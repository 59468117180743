import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import { CONFIG } from '../../../config-global';

// ----------------------------------------------------------------------
const regEx = /^[^2]*/
const initialState = {
  intial: false,
  responseMessage: null,
  success: false,
  isLoading: false,
  error: null,
  organization: {},
  organizations: [],
  activeOrganization: [],
  filterBy: '',
  page: 0,
  rowsPerPage: 100,
};

const slice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.initial = true;
    },

    // GET 
    getOrganizationsSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.organizations = action.payload;
      state.initial = true;
    },

    // GET 
    getActiveOrganizationsSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeOrganizations = action.payload;
      state.initial = true;
    },

    // GET 
    getOrganizationSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.organization = action.payload;
      state.initial = true;
    },

    // SET RES MESSAGE
    setResponseMessage(state, action) {
      state.responseMessage = action.payload;
      state.isLoading = false;
      state.success = true;
      state.initial = true;
    },

    // RESET 
    resetOrganization(state){
      state.organization = {};
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // reset  
    resetActiveOrganizations(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeOrganizations = [];
      state.initial = true;
    },

    // RESET 
    resetOrganizations(state){
      state.organizations = [];
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },
        // Set FilterBy
    setFilterBy(state, action) {
      state.filterBy = action.payload;
    },
    // Set PageRowCount
    ChangeRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    // Set PageNo
    ChangePage(state, action) {
      state.page = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetOrganizations,
  resetActiveOrganizations,
  resetOrganization,
  setFilterBy,
  ChangeRowsPerPage,
  ChangePage,
} = slice.actions;
// ----------------------------------------------------------------------

export function addOrganization(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        name: params.name,
        type: params.type,
        isActive: params.isActive,
      }

      const response = await axios.post(`${CONFIG.SERVER_URL}organizations/`, data);
      dispatch(slice.actions.setResponseMessage('Organization Saved successfully'));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
      // dispatch(slice.actions.hasError(error?.message));
    }
  };
}
// ----------------------------------------------------------------------

export function updateOrganization(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const data = {
        name: params.name,
        type: params.type,
        isActive: params.isActive,
        isDefault: params.isDefault,
      }
      await axios.patch(`${CONFIG.SERVER_URL}organizations/update/${id}`, data);
      dispatch(slice.actions.setResponseMessage('Organization updated successfully'));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function getOrganizations() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}organizations`);
      dispatch(slice.actions.getOrganizationsSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Organizations loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

export function getActiveOrganization() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}organizations`,
      {
        params: {
          isActive: true,
        }
      }
      );
      dispatch(slice.actions.getActiveOrganizationsSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Active Organizations loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getOrganization(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}organizations/${id}`);
      dispatch(slice.actions.getOrganizationSuccess(response.data));
    } catch (error) {
      console.error(error?.message);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteOrganization(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try{
      const response = await axios.get(`${CONFIG.SERVER_URL}organizations/delete/${id}`);
      if(regEx.test(response.status)){
        dispatch(slice.actions.setResponseMessage(response.data));
        dispatch(resetOrganization());
      }
      return response;
    } catch (error) {
      console.error(error?.message);
      throw error;
    }
  };
}
