// routes
import { PATH_AUTH } from '../routes/paths';
// utils
import axios from '../utils/axios';

// ----------------------------------------------------------------------

function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;
  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
  alert('Your session has expired. Please login again');

    localStorage.removeItem('accessToken');

    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');

    delete axios.defaults.headers.common.Authorization;
  }
};

// ----------------------------------------------------------------------

export const getUserAccess = ( roles ) => {

    let userRoles

    if( Array.isArray( roles ) && roles.length > 0 ) {
      userRoles = roles
    } else {
      userRoles = JSON.parse(localStorage.getItem('userRoles'))
    }

    let isSuperAdmin = false;
    let isManager = false;
    let isOperator = false;
    const isCustomer = true;

    if(userRoles?.some((role) => role?.roleType?.toLowerCase() === 'superadmin')){      
      isSuperAdmin = true;
      isManager=true;
      isOperator = true;
    } else if( userRoles?.some((role) => role?.roleType?.toLowerCase() === 'manager') ){
      isSuperAdmin = false;
      isManager=true;
      isOperator = true;
    } else if(userRoles?.some((role) => role?.roleType?.toLowerCase() === 'operator')){
      isSuperAdmin = false;
      isManager=false;
      isOperator = true;
    } else if(userRoles?.some((role) => role?.roleType?.toLowerCase() === 'customer')){
      isSuperAdmin = false;
      isManager=false;
      isOperator = false;
    } 

    return {
      isSuperAdmin,
      isManager,
      isOperator,
      isCustomer
    };
};
