import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import { CONFIG } from '../../../config-global';

// ----------------------------------------------------------------------
const initialState = {
  intial: false,
  isLoading: false,
  success: false,
  error: null,
  responseMessage: null,
  sites: [],
  activeSites: [],
  site: {},
  siteDialogVisibility: false,
  filterBy: '',
  page: 0,
  rowsPerPage: 100,
};

const slice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.error = null;
      state.isLoading = true;
    },

    // SET ADD / UPDATE DIALOG
    setSiteDialogVisibility(state, action){
      state.siteDialogVisibility = action.payload;
    },

    // GET Sites
    getSitesSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.sites = action.payload;
      state.initial = true;
    },

    // GET Active Sites
    getActiveSitesSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeSites = action.payload;
      state.initial = true;
    },

    // GET Site
    getSiteSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.site = action.payload;
      state.initial = true;
    },
    
    // RESET SITE
    resetSite(state){
      state.site = null;
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // RESET SITES
    resetSites(state){
      state.sites = [];
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // RESET Active SITES
    resetActiveSites(state){
      state.activeSites = [];
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.initial = true;
    },

    setResponseMessage(state, action) {
      state.responseMessage = action.payload;
      state.isLoading = false;
      state.success = true;
      state.initial = true;
    },

    // Set FilterBy
    setFilterBy(state, action) {
      state.filterBy = action.payload;
    },
    // Set PageRowCount
    ChangeRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    // Set PageNo
    ChangePage(state, action) {
      state.page = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setSiteDialogVisibility,
  setResponseMessage,
  resetSite,
  resetSites,
  resetActiveSites,
  setFilterBy,
  ChangeRowsPerPage,
  ChangePage,
} = slice.actions;

// ----------------------------------------------------------------------

export function addSite(params) {
  return async (dispatch) => {
    dispatch(slice.actions.setSiteDialogVisibility(false));
    dispatch(slice.actions.startLoading());
      try {
        const data = {
          name: params.name,
          customer: params.customer,
          email: params.email,
          phone: params.phone,
          lat: params.lat,
          long: params.long,
          address: {
            street:params.street,
            suburb:params.suburb,
            city:params.city,
            region:params.region,
            postcode:params.postcode,
            country:params.country,
          },
          isActive: params.isActive,
        };
        
        const response = await axios.post(`${CONFIG.SERVER_URL}sites/`, data);
        dispatch(slice.actions.getSiteSuccess(response.data)) 
      } catch (error) {
        console.error(error?.message);
        dispatch(slice.actions.hasError(error?.message));
        throw error;
      }
  };
}

// ----------------------------------------------------------------------

export function updateSite(params, id) {
  return async (dispatch) => {
    dispatch(slice.actions.setSiteDialogVisibility(false));
    dispatch(slice.actions.startLoading());
      try {
        const data = {
          name: params.name,
          customer: params.customer,
          email: params.email,
          phone: params.phone,
          lat: params.lat,
          long: params.long,
          address: {
            street:params.street,
            suburb:params.suburb,
            city:params.city,
            region:params.region,
            postcode:params.postcode,
            country:params.country,
          },
          isActive: params.isActive,
        };
        
        const response = await axios.patch(`${CONFIG.SERVER_URL}sites/update/${id}`, data);
        dispatch(slice.actions.getSiteSuccess(response.data))
      } catch (error) {
        console.error(error?.message);
        dispatch(slice.actions.hasError(error?.message));
        throw error;
      }
  };
}

// ----------------------------------------------------------------------

export function createCustomerStiesCSV(customerID) {
  return async (dispatch) => {
    try {
      if(customerID){
        const response = axios.get(`${CONFIG.SERVER_URL}crm/customers/${customerID}/sites/export` , 
        {
          params: {
            isArchived: false,
            orderBy : {
              createdAt:-1
            }
          }
        });

        response.then((res) => {
          const fileName = "CustomerSites.csv";
          const blob = new Blob([res.data], { type: 'text/csv;charset=utf-8' });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          dispatch(slice.actions.setResponseMessage('Customer Sites CSV generated successfully'));
        }).catch((error) => {
          console.error(error?.message);
        });
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

export function getSites(customerID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const params = {
        customer:customerID,
        // orderBy : {
        //   createdAt: -1
        // }
      }
      
      const response = await axios.get(`${CONFIG.SERVER_URL}sites`, {params});
      dispatch(slice.actions.getSitesSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Sites loaded successfully'));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getActiveSites(customerID) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const params = {
        customer:customerID,
        // isActive: true
      }
      const response = await axios.get(`${CONFIG.SERVER_URL}sites`, {params});
      dispatch(slice.actions.getActiveSitesSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Sites loaded successfully'));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

// ----------------------------------------------------------------------

export function getSite(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}sites/${id}`);
      dispatch(slice.actions.getSiteSuccess(response.data));
    } catch (error) {
      console.error(error?.message);
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteSite(customerID, id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        customer: customerID,
      };
      const response = await axios.patch(`${CONFIG.SERVER_URL}sites/delete/${id}`,data);
      dispatch(slice.actions.setResponseMessage(response.data));
    } catch (error) {
      console.error(error?.message);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}


