import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { CONFIG } from '../../config-global';

// ----------------------------------------------------------------------
const regEx = /^[^2]*/
const initialState = {
  intial: false,
  responseMessage: null,
  success: false,
  isLoading: false,
  error: null,
  boardOfDirector: {},
  boardOfDirectors: [],
  activeBoardOfDirectors: [],
  filterBy: '',
  page: 0,
  rowsPerPage: 100,
};

const slice = createSlice({
  name: 'boardOfDirector',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.initial = true;
    },

    // GET 
    getBoardOfDirectorsSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.boardOfDirectors = action.payload;
      state.initial = true;
    },

    // GET 
    getActiveBoardOfDirectorsSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeBoardOfDirectors = action.payload;
      state.initial = true;
    },

    // GET 
    getBoardOfDirectorSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.boardOfDirector = action.payload;
      state.initial = true;
    },

    // SET RES MESSAGE
    setResponseMessage(state, action) {
      state.responseMessage = action.payload;
      state.isLoading = false;
      state.success = true;
      state.initial = true;
    },

    // RESET 
    resetBoardOfDirector(state){
      state.boardOfDirector = {};
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // reset  
    resetActiveBoardOfDirectors(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeBoardOfDirectors = [];
      state.initial = true;
    },

    // RESET 
    resetBoardOfDirectors(state){
      state.boardOfDirectors = [];
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },
        // Set FilterBy
    setFilterBy(state, action) {
      state.filterBy = action.payload;
    },
    // Set PageRowCount
    ChangeRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    // Set PageNo
    ChangePage(state, action) {
      state.page = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetBoardOfDirectors,
  resetActiveBoardOfDirectors,
  resetBoardOfDirector,
  setFilterBy,
  ChangeRowsPerPage,
  ChangePage,
} = slice.actions;
// ----------------------------------------------------------------------

export function addBoardOfDirector(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const formData = new FormData();
      formData.append('title', params.title);
      formData.append('ID', params.ID);
      formData.append('fullName', params.fullName);
      formData.append('designationBargad', params.designationBargad?._id);
      formData.append('designation', params.designation?._id);
      formData.append('organization', params.organization?._id);
      formData.append('postalAddress', params.postalAddress);
      formData.append('province', params.province?._id);
      formData.append('district', params.district?._id);
      formData.append('cnic', params.cnic);
      formData.append('cv', params.cv);
      formData.append('picture', params.picture);
      formData.append('otherDocument', params.otherDocument);
      formData.append('dob', params.dob);
      formData.append('placeOfBirth', params.placeOfBirth);
      formData.append('occupation', params.occupation);
      formData.append('currentEmployer', params.currentEmployer);
      formData.append('zipCode', params.zipCode);
      formData.append('fax', params.fax);
      formData.append('email', params.email);
      formData.append('phone', params.phone);
      formData.append('mobile', params.mobile);
      formData.append('isActive', params.isActive);
      formData.append('status', params.status);

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      const response = await axios.post(`${CONFIG.SERVER_URL}boardOfDirectors/`, formData, config);
      dispatch(slice.actions.setResponseMessage('BoardOfDirector Saved successfully'));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
      // dispatch(slice.actions.hasError(error?.message));
    }
  };
}
// ----------------------------------------------------------------------

export function updateBoardOfDirector(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const formData = new FormData();
      formData.append('title', params.title);
      formData.append('ID', params.ID);
      formData.append('fullName', params.fullName);
      formData.append('designationBargad', params.designationBargad?._id);
      formData.append('designation', params.designation?._id);
      formData.append('organization', params.organization?._id);
      formData.append('postalAddress', params.postalAddress);
      formData.append('province', params.province?._id);
      formData.append('district', params.district?._id);
      formData.append('cnic', params.cnic);
      formData.append('cv', params.cv);
      formData.append('picture', params.picture);
      formData.append('otherDocument', params.otherDocument);
      formData.append('dob', params.dob);
      formData.append('placeOfBirth', params.placeOfBirth);
      formData.append('occupation', params.occupation);
      formData.append('currentEmployer', params.currentEmployer);
      formData.append('zipCode', params.zipCode);
      formData.append('fax', params.fax);
      formData.append('email', params.email);
      formData.append('phone', params.phone);
      formData.append('mobile', params.mobile);
      formData.append('isActive', params.isActive);
      formData.append('status', params.status);
      
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      
      await axios.patch(`${CONFIG.SERVER_URL}boardOfDirectors/update/${id}`, formData, config);
      dispatch(slice.actions.setResponseMessage('BoardOfDirector updated successfully'));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function getBoardOfDirectors() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}boardOfDirectors`);
      dispatch(slice.actions.getBoardOfDirectorsSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('BoardOfDirectors loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

export function getActiveBoardOfDirector() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}boardOfDirectors`,
      {
        params: {
          isActive: true,
        }
      }
      );
      dispatch(slice.actions.getActiveBoardOfDirectorsSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Active BoardOfDirectors loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getBoardOfDirector(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}boardOfDirectors/${id}`);
      dispatch(slice.actions.getBoardOfDirectorSuccess(response.data));
    } catch (error) {
      console.error(error?.message);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteBoardOfDirector(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try{
      const response = await axios.get(`${CONFIG.SERVER_URL}boardOfDirectors/delete/${id}`);
      if(regEx.test(response.status)){
        dispatch(slice.actions.setResponseMessage(response.data));
        dispatch(resetBoardOfDirector());
      }
      return response;
    } catch (error) {
      console.error(error?.message);
      throw error;
    }
  };
}
