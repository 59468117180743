import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import { CONFIG } from '../../../config-global';

// ----------------------------------------------------------------------
const regEx = /^[^2]*/
const initialState = {
  intial: false,
  responseMessage: null,
  success: false,
  isLoading: false,
  error: null,
  category: {},
  categories: [],
  activeCategories: [],
  filterBy: '',
  page: 0,
  rowsPerPage: 100,
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.initial = true;
    },

    // GET  ITEM CATEGORIES
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.categories = action.payload;
      state.initial = true;
    },

    // GET  ACTIVE ITEM CATEGORIES
    getActiveCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeCategories = action.payload;
      state.initial = true;
    },

    // GET Category
    getCategorySuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.category = action.payload;
      state.initial = true;
    },

    // SET RES MESSAGE
    setResponseMessage(state, action) {
      state.responseMessage = action.payload;
      state.isLoading = false;
      state.success = true;
      state.initial = true;
    },

    // RESET ITEM CATEGORY
    resetCategory(state){
      state.category = {};
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // reset  ACTIVE ITEM CATEGORIES
    resetActiveCategories(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeCategories = [];
      state.initial = true;
    },

    // RESET ITEM CATEGORIES
    resetCategories(state){
      state.categories = [];
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },
        // Set FilterBy
    setFilterBy(state, action) {
      state.filterBy = action.payload;
    },
    // Set PageRowCount
    ChangeRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    // Set PageNo
    ChangePage(state, action) {
      state.page = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetCategories,
  resetActiveCategories,
  resetCategory,
  setFilterBy,
  ChangeRowsPerPage,
  ChangePage,
} = slice.actions;
// ----------------------------------------------------------------------

export function addCategory(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        name: params.name,
        desc: params.desc,
        isActive: params.isActive,
        isDefault: params.isDefault,
      }

      const response = await axios.post(`${CONFIG.SERVER_URL}categories/`, data);
      dispatch(slice.actions.setResponseMessage('Category Saved successfully'));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
      // dispatch(slice.actions.hasError(error?.message));
    }
  };
}
// ----------------------------------------------------------------------

export function updateCategory(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const data = {
        name: params.name,
        desc: params.desc,
        isActive: params.isActive,
        isDefault: params.isDefault,
      }
      await axios.patch(`${CONFIG.SERVER_URL}categories/update/${id}`, data);
      dispatch(slice.actions.setResponseMessage('Category updated successfully'));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function getCategories() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}categories`);
      dispatch(slice.actions.getCategoriesSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Categories loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

export function getActiveCategories() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}categories`,
      {
        params: {
          isActive: true,
        }
      }
      );
      dispatch(slice.actions.getActiveCategoriesSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Active Categories loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getCategory(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}categories/${id}`);
      dispatch(slice.actions.getCategorySuccess(response.data));
    } catch (error) {
      console.error(error?.message);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCategory(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try{
      const response = await axios.get(`${CONFIG.SERVER_URL}categories/delete/${id}`);
      if(regEx.test(response.status)){
        dispatch(slice.actions.setResponseMessage(response.data));
        dispatch(resetCategory());
      }
      return response;
    } catch (error) {
      console.error(error?.message);
      throw error;
    }
  };
}
