import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { CONFIG } from '../../config-global';

// ----------------------------------------------------------------------
const regEx = /^[^2]*/
const initialState = {
  intial: false,
  responseMessage: null,
  success: false,
  isLoading: false,
  error: null,
  project: {},
  projects: [],
  activeProjects: [],
  filterBy: '',
  page: 0,
  rowsPerPage: 100,
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.initial = true;
    },

    // GET 
    getProjectsSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.projects = action.payload;
      state.initial = true;
    },

    // GET 
    getActiveProjectsSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeProjects = action.payload;
      state.initial = true;
    },

    // GET 
    getProjectSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.project = action.payload;
      state.initial = true;
    },

    // SET RES MESSAGE
    setResponseMessage(state, action) {
      state.responseMessage = action.payload;
      state.isLoading = false;
      state.success = true;
      state.initial = true;
    },

    // RESET 
    resetProject(state){
      state.project = {};
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // reset  
    resetActiveProjects(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeProjects = [];
      state.initial = true;
    },

    // RESET 
    resetProjects(state){
      state.projects = [];
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },
        // Set FilterBy
    setFilterBy(state, action) {
      state.filterBy = action.payload;
    },
    // Set PageRowCount
    ChangeRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    // Set PageNo
    ChangePage(state, action) {
      state.page = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetProjects,
  resetActiveProjects,
  resetProject,
  setFilterBy,
  ChangeRowsPerPage,
  ChangePage,
} = slice.actions;
// ----------------------------------------------------------------------

export function addProject(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const formData = new FormData();
      formData.append("name", params.name);
      formData.append("year", params.year);
      formData.append("donor", params.donor?._id);
      formData.append("location", params.location);
      formData.append("budgetPkr", params.budgetPkr);
      formData.append("budgetEur", params.budgetEur);
      formData.append("budgetUsd", params.budgetUsd);
      formData.append("beneficiaryCategory", params.beneficiaryCategory?._id);
      formData.append("beneficiaryStatus", params.beneficiaryStatus);
      formData.append("startDate", params.startDate);
      formData.append("endDate", params.endDate);
      formData.append("isActive", params.isActive);
      formData.append("status", params.status);
      formData.append("name", params.name);

      if(Array.isArray(params.themes) && params.themes.length>0) {
        params.themes.map((p)=>{
          return formData.append("themes[]", p._id);
        });
      }

      if(Array.isArray(params.objectives) && params.objectives.length>0) {
        params.objectives.map((p)=>{
          return formData.append("objectives[]", p._id);
        });
      }

      if(Array.isArray(params.activities) && params.activities.length>0) {
        params.activities.map((p)=>{
          return formData.append("activities[]", p._id);
        });
      }

      if(Array.isArray(params.directBeneficiaries) && params.directBeneficiaries.length>0) {
        params.directBeneficiaries.map((p)=>{
          return formData.append("directBeneficiaries[]", p._id);
        });
      }

      if(Array.isArray(params.documents) && params.documents.length>0) {
        params.documents.map((p)=>{
          return formData.append("documents", p);
        });
      }

      if(Array.isArray(params.images) && params.images.length>0) {
        params.images.map((p)=>{
          return formData.append("images", p);
        });
      }

      if(Array.isArray(params.news) && params.news.length>0) {
        params.news.map((p)=>{
          return formData.append("news[]", p._id);
        });
      }

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      const response = await axios.post(`${CONFIG.SERVER_URL}projects/`, formData, config);
      dispatch(slice.actions.setResponseMessage('Project Saved successfully'));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
      // dispatch(slice.actions.hasError(error?.message));
    }
  };
}
// ----------------------------------------------------------------------

export function updateProject(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const formData = new FormData();
      formData.append("name", params.name);
      formData.append("year", params.year);
      formData.append("donor", params.donor?._id);
      formData.append("location", params.location);
      formData.append("budgetPkr", params.budgetPkr);
      formData.append("budgetEur", params.budgetEur);
      formData.append("budgetUsd", params.budgetUsd);
      formData.append("beneficiaryCategory", params.beneficiaryCategory?._id);
      formData.append("beneficiaryStatus", params.beneficiaryStatus);
      formData.append("startDate", params.startDate);
      formData.append("endDate", params.endDate);
      formData.append("isActive", params.isActive);
      formData.append("status", params.status);
      formData.append("name", params.name);

      if(Array.isArray(params.themes) && params.themes.length>0) {
        params.themes.map((p)=>{
          return formData.append("themes[]", p._id);
        });
      }

      if(Array.isArray(params.objectives) && params.objectives.length>0) {
        params.objectives.map((p)=>{
          return formData.append("objectives[]", p._id);
        });
      }

      if(Array.isArray(params.activities) && params.activities.length>0) {
        params.activities.map((p)=>{
          return formData.append("activities[]", p._id);
        });
      }

      if(Array.isArray(params.directBeneficiaries) && params.directBeneficiaries.length>0) {
        params.directBeneficiaries.map((p)=>{
          return formData.append("directBeneficiaries[]", p._id);
        });
      }

      if(Array.isArray(params.documents) && params.documents.length>0) {
        params.documents.map((p)=>{
          return formData.append("documents", p);
        });
      }

      if(Array.isArray(params.images) && params.images.length>0) {
        params.images.map((p)=>{
          return formData.append("images", p);
        });
      }

      if(Array.isArray(params.news) && params.news.length>0) {
        params.news.map((p)=>{
          return formData.append("news[]", p._id);
        });
      }

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      

      await axios.patch(`${CONFIG.SERVER_URL}projects/update/${id}`, formData, config);
      dispatch(slice.actions.setResponseMessage('Project updated successfully'));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function getProjects() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}projects`);
      dispatch(slice.actions.getProjectsSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Projects loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

export function getActiveProject() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}projects`,
      {
        params: {
          isActive: true,
        }
      }
      );
      dispatch(slice.actions.getActiveProjectsSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Active Projects loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getProject(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}projects/${id}`);
      dispatch(slice.actions.getProjectSuccess(response.data));
    } catch (error) {
      console.error(error?.message);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteProject(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try{
      const response = await axios.get(`${CONFIG.SERVER_URL}projects/delete/${id}`);
      if(regEx.test(response.status)){
        dispatch(slice.actions.setResponseMessage(response.data));
        dispatch(resetProject());
      }
      return response;
    } catch (error) {
      console.error(error?.message);
      throw error;
    }
  };
}
