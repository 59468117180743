import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import { CONFIG } from '../../../config-global';

// ----------------------------------------------------------------------
const regEx = /^[^2]*/
const initialState = {
  intial: false,
  responseMessage: null,
  success: false,
  isLoading: false,
  error: null,
  theme: {},
  themes: [],
  activeTheme: [],
  filterBy: '',
  page: 0,
  rowsPerPage: 100,
};

const slice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      state.initial = true;
    },

    // GET 
    getThemesSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.themes = action.payload;
      state.initial = true;
    },

    // GET 
    getActiveThemesSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeThemes = action.payload;
      state.initial = true;
    },

    // GET 
    getThemeSuccess(state, action) {
      state.isLoading = false;
      state.success = true;
      state.theme = action.payload;
      state.initial = true;
    },

    // SET RES MESSAGE
    setResponseMessage(state, action) {
      state.responseMessage = action.payload;
      state.isLoading = false;
      state.success = true;
      state.initial = true;
    },

    // RESET 
    resetTheme(state){
      state.theme = {};
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },

    // reset  
    resetActiveThemes(state, action) {
      state.isLoading = false;
      state.success = true;
      state.activeThemes = [];
      state.initial = true;
    },

    // RESET 
    resetThemes(state){
      state.themes = [];
      state.responseMessage = null;
      state.success = false;
      state.isLoading = false;
    },
        // Set FilterBy
    setFilterBy(state, action) {
      state.filterBy = action.payload;
    },
    // Set PageRowCount
    ChangeRowsPerPage(state, action) {
      state.rowsPerPage = action.payload;
    },
    // Set PageNo
    ChangePage(state, action) {
      state.page = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetThemes,
  resetActiveThemes,
  resetTheme,
  setFilterBy,
  ChangeRowsPerPage,
  ChangePage,
} = slice.actions;
// ----------------------------------------------------------------------

export function addTheme(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const data = {
        name: params.name,
        desc: params.desc,
        type: params.type,
        status: 'active',
        isActive: params.isActive,
      }

      const response = await axios.post(`${CONFIG.SERVER_URL}themes/`, data);
      dispatch(slice.actions.setResponseMessage('Theme Saved successfully'));
      return response;
    } catch (error) {
      console.log(error);
      throw error;
      // dispatch(slice.actions.hasError(error?.message));
    }
  };
}
// ----------------------------------------------------------------------

export function updateTheme(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {

      const data = {
        name: params.name,
        desc: params.desc,
        type: params.type,
        status: 'active',
        isActive: params.isActive,
      }
      await axios.patch(`${CONFIG.SERVER_URL}themes/update/${id}`, data);
      dispatch(slice.actions.setResponseMessage('Theme updated successfully'));
    } catch (error) {
      console.log(error);
    }
  };
}
// ----------------------------------------------------------------------

export function getThemes() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}themes`);
      dispatch(slice.actions.getThemesSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Themes loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
    }
  };
}

export function getActiveTheme() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}themes`,
      {
        params: {
          isActive: true,
        }
      }
      );
      dispatch(slice.actions.getActiveThemesSuccess(response.data));
      dispatch(slice.actions.setResponseMessage('Active Themes loaded successfully'));

    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function getTheme(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${CONFIG.SERVER_URL}themes/${id}`);
      dispatch(slice.actions.getThemeSuccess(response.data));
    } catch (error) {
      console.error(error?.message);
      dispatch(slice.actions.hasError(error?.message));
      throw error;
    }
  };
}

// ----------------------------------------------------------------------

export function deleteTheme(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try{
      const response = await axios.get(`${CONFIG.SERVER_URL}themes/delete/${id}`);
      if(regEx.test(response.status)){
        dispatch(slice.actions.setResponseMessage(response.data));
        dispatch(resetTheme());
      }
      return response;
    } catch (error) {
      console.error(error?.message);
      throw error;
    }
  };
}
